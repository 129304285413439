import React from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import TextFiled from "@mui/material/TextField";
import Calender from "@mui/icons-material/CalendarToday";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import InputAdornment from "@mui/material/InputAdornment";
import Filter from "@mui/icons-material/FilterAlt";

import * as theme from "../styles/index";

const Filters = ({ handleSetTimeFrame }) => {
  const [state, setState] = React.useState({
    timeRange: "",
    isModalOpen: false,
  });

  const handleChangeTimeRange = (e) => {
    const { value, name } = e.target;

    setState((state) => ({ ...state, [name]: value }));
  };

  const handleOpenModal = () =>
    setState((state) => ({ ...state, isModalOpen: true }));

  const handleCloseModal = () =>
    setState((state) => ({ ...state, isModalOpen: false }));

  React.useEffect(() => {
    if (state.timeRange === "today") {
      handleSetTimeFrame(1);
    } else if (state.timeRange === "in_a_week") {
      handleSetTimeFrame(7);
    } else if (state.timeRange === "in_a_month") {
      handleSetTimeFrame(30);
    } else {
      handleSetTimeFrame(720);
    }
  }, [state.timeRange]);

  return (
    <React.Fragment>
      <FilterMenu>
        <FilterMenuContainer>
          <FilterMenuSelection
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalenderIcon />
                </InputAdornment>
              ),
            }}
            select
            value={state.timeRange}
            variant="standard"
            onChange={handleChangeTimeRange}
            name={"timeRange"}
          >
            <FilterMenuOption value="today">今天</FilterMenuOption>
            <FilterMenuOption value="in_a_week">1周內</FilterMenuOption>
            <FilterMenuOption value="in_a_month">1個月內</FilterMenuOption>
          </FilterMenuSelection>
        </FilterMenuContainer>
        <FilterDropdownContainer>
          <FilterIcon onClick={handleOpenModal} />
        </FilterDropdownContainer>
      </FilterMenu>

      <FilterContainer>
        <Mask isModalOpen={state.isModalOpen}>
          <p>尚未開放</p>
        </Mask>
        <FilterContainerModal isModalOpen={state.isModalOpen}>
          <FilterContainerModalHeader>
            <FilterIcon />
            <FilterContainerModalHeaderText>
              篩選器
            </FilterContainerModalHeaderText>
          </FilterContainerModalHeader>
          <FilterContainerModalSelectionContainer>
            <FilterContainerModalSelectionItem>
              <FilterContainerModalSelectionItemKey>
                <FilterContainerModalSelectionItemKeyText>
                  種類
                </FilterContainerModalSelectionItemKeyText>
              </FilterContainerModalSelectionItemKey>
              <FilterContainerModalSelectionItemSelect select />
            </FilterContainerModalSelectionItem>
            <FilterContainerModalSelectionItem>
              {" "}
              <FilterContainerModalSelectionItemKey>
                <FilterContainerModalSelectionItemKeyText>
                  毛色
                </FilterContainerModalSelectionItemKeyText>
              </FilterContainerModalSelectionItemKey>
              <FilterContainerModalSelectionItemSelect select />
            </FilterContainerModalSelectionItem>
            <FilterContainerModalSelectionItem>
              {" "}
              <FilterContainerModalSelectionItemKey>
                <FilterContainerModalSelectionItemKeyText>
                  品種
                </FilterContainerModalSelectionItemKeyText>
              </FilterContainerModalSelectionItemKey>
              <FilterContainerModalSelectionItemSelect select />
            </FilterContainerModalSelectionItem>
            <FilterContainerModalSelectionItem>
              {" "}
              <FilterContainerModalSelectionItemKey>
                <FilterContainerModalSelectionItemKeyText>
                  配件
                </FilterContainerModalSelectionItemKeyText>
              </FilterContainerModalSelectionItemKey>
              <FilterContainerModalSelectionItemSelect select />
            </FilterContainerModalSelectionItem>
          </FilterContainerModalSelectionContainer>
          <FilterContainerModalButtonContainer>
            <FilterContainerModalSubButton>
              儲存 - 遇相符通知我
            </FilterContainerModalSubButton>
            <FilterContainerModalCanSubButton>
              清除 - 不要通知我
            </FilterContainerModalCanSubButton>
          </FilterContainerModalButtonContainer>
        </FilterContainerModal>
        <FilterContainerBackdrop
          isModalOpen={state.isModalOpen}
          onClick={handleCloseModal}
        />
      </FilterContainer>
    </React.Fragment>
  );
};

const Mask = styled.div`
  position: absolute;
  width: 80vw;
  margin: 100px 10vw;
  min-height: 60vh;
  background-color: ${theme.coolGrey};
  opacity: 0.7;
  left: ${(p) => (p.isModalOpen ? "0px" : "-120vw")};
  top: 0px;
  z-index: 16;
  border-radius: 20px;
  transition: 0.2s left linear;
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    font-size: 2rem;
    color: ${theme.white};
  }
`;

const FilterMenu = styled.div`
  display: flex;
  transform: translateY(-5px);
`;

const FilterMenuContainer = styled.div`
  background-color: ${theme.white};
  border-radius: 50px 0px 0px 50px;
`;

const FilterMenuSelection = styled(TextFiled)`
  /* background-color: red; */
  min-width: 90px !important;
  padding: 5px 5px 5px 15px !important;

  > div {
    #mui-component-select-timeRange {
      transform: translateY(3px);
    }
  }

  > div::after {
    border-bottom: 1px solid ${theme.white} !important;
    border-style: none !important;
  }
  > div::before {
    border-bottom: 1px solid ${theme.white} !important;
    border-style: none !important;
  }

  .MuiSvgIcon-root {
    background-color: ${theme.primaryColor};
    border-radius: 50%;
    transform: translateX(2.5px);
  }
`;

const FilterMenuOption = styled(MenuItem)``;

const FilterDropdownContainer = styled.div`
  background-color: ${theme.primaryColor};
  border-radius: 0px 50px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 5px;
`;

const CalenderIcon = styled(Calender)`
  width: 20px !important;
  height: 20px !important;
  color: ${theme.primaryColor};
  background-color: ${theme.white} !important;
`;

const FilterIcon = styled(Filter)`
  width: 20px !important;
  height: 20px !important;
  color: ${theme.white};
  cursor: pointer;
`;

const FilterContainer = styled.div``;

const FilterContainerBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: ${(p) => (p.isModalOpen ? "block" : "none")};
  background-color: ${theme.black};
  left: 0px;
  top: 0px;
  z-index: -1;
  opacity: 0.4;
  transition: 0.2s display ease-in;
`;

const FilterContainerModal = styled.div`
  position: absolute;
  width: 80vw;
  margin: 100px 10vw;
  min-height: 60vh;
  background-color: ${theme.white};
  left: ${(p) => (p.isModalOpen ? "0px" : "-120vw")};
  top: 0px;
  z-index: 15;
  border-radius: 20px;
  transition: 0.2s left linear;
`;

const FilterContainerModalHeader = styled.div`
  background-color: ${theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
`;

const FilterContainerModalHeaderText = styled.p`
  padding-left: 5px;
`;

const FilterContainerModalSelectionContainer = styled.div``;

const FilterContainerModalSelectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 10px 10%;
`;

const FilterContainerModalSelectionItemKey = styled.div`
  padding-right: 10%;
`;

const FilterContainerModalSelectionItemKeyText = styled.p`
  background-color: ${theme.grayColor};
  padding: 2.5px 5px;
  border-radius: 10px;
  min-width: 35px;
  font-size: 12px;
  text-align: center;
`;

const FilterContainerModalSelectionItemSelect = styled(TextFiled)`
  width: 90%;
  > div {
    height: 40px;
    border-radius: 20px;
    > fieldset {
      border-color: ${theme.primaryColor} !important;
    }
  }
`;

const FilterContainerModalButtonContainer = styled.div``;

const FilterContainerModalSubButton = styled.div`
  width: 80%;
  margin: 10px 10%;
  background-color: ${theme.primaryColor};
  border-radius: 20px;
  padding: 10px 0px;
  text-align: center;
`;

const FilterContainerModalCanSubButton = styled(FilterContainerModalSubButton)`
  background-color: ${theme.grayColor};
`;

export default Filters;
