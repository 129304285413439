export const dogs = [
  { name: "米克斯" },
  { name: "貴賓" },
  { name: "米格魯" },
  { name: "日本種" },
  { name: "哈士奇" },
  { name: "博美" },
  { name: "法國鬥牛犬" },
  { name: "英國查理士獵犬" },
  { name: "高山犬" },
  { name: "阿富汗" },
  { name: "大麥町" },
  { name: "敖犬" },
  { name: "蘇俄牧羊犬" },
  { name: "拳師犬" },
  { name: "獵狐梗" },
  { name: "挪威納" },
  { name: "狐狸" },
  { name: "巴吉度" },
  { name: "杜賓" },
  { name: "可卡" },
  { name: "吉娃娃" },
  { name: "約克夏" },
  { name: "臘腸" },
  { name: "柴犬" },
  { name: "狼犬" },
  { name: "黃金獵犬" },
  { name: "拉布拉多" },
  { name: "西施" },
  { name: "雪納瑞" },
  { name: "英國鬥牛犬" },
  { name: "巴戈" },
  { name: "柯基" },
  { name: "迷你品" },
  { name: "邊境牧羊犬" },
  { name: "伯恩山" },
  { name: "北京犬" },
  { name: "比特" },
  { name: "惠比特犬" },
  { name: "西高地白梗" },
  { name: "梗犬" },
  { name: "波士頓" },
  { name: "秋田" },
  { name: "喜樂蒂" },
  { name: "蝴蝶犬" },
  { name: "鬆獅犬" },
  { name: "古代牧羊犬" },
  { name: "傑克羅素" },
  { name: "比熊" },
  { name: "比利時馬利諾犬" },
  { name: "美國鬥牛犬" },
  { name: "拉薩" },
  { name: "其他" },
];

export const cats = [
  { name: "混種貓" },
  { name: "金吉拉" },
  { name: "俄羅斯藍貓" },
  { name: "英國藍貓" },
  { name: "緬絪" },
  { name: "豹貓" },
  { name: "加菲貓" },
  { name: "摺耳貓" },
  { name: "暹羅貓" },
  { name: "波斯" },
  { name: "英國短毛貓" },
  { name: "美國短毛貓" },
  { name: "其他" },
];
