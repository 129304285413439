import styled from "styled-components";
import { useSelector } from "react-redux";

import Spinner from "./Spinner";

const Loading = () => {
  const { loadingStatus } = useSelector((state) => state.markers);

  return (
    <LoadingWrapper loading={loadingStatus === "loading"}>
      <Spinner />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: ${(p) => (p.loading ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`;

export default Loading;
