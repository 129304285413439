import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

import * as theme from "../styles/index";
import { data } from "../mocks/index";
import drinking from "../images/drinking.gif";
import stray_dog from "../images/stray_dog.png";
import arrow from "../images/arrow.png";
import donation from "../images/donation.png";

import "swiper/swiper-bundle.css";

const CurrentStatus = ({}) => {
  const [state, setState] = React.useState({
    openDonationPage: false,
    domain: "",
    maintain: "",
    manpower: "",
    promotion: "",
    server: "",
    thanksTo: [],
  });

  const handleToggleDonationModal = () =>
    setState((state) => ({
      ...state,
      openDonationPage: !state.openDonationPage,
    }));

  const handleFetchData = () => {
    return axios
      .get("https://hello-yu-chen-default-rtdb.firebaseio.com/waypethome.json")
      .then((res) => {
        const { domain, maintain, manpower, promotion, server, thanksTo } =
          res.data;

        setState((state) => ({
          ...state,
          domain,
          maintain,
          manpower,
          promotion,
          server,
          thanksTo,
        }));
      });
  };

  React.useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <React.Fragment>
      <CurrentStatusWrapper>
        <DonationModal
          isOpen={state.openDonationPage}
          handleToggleDonationModal={handleToggleDonationModal}
        />
        <DrinkingImg src={drinking} alt={"喝咖啡 gif"} />
        <GoalInfoContainer>
          <CurrentHeadContainer>
            <CurrentHeadText>我們的目標</CurrentHeadText>
          </CurrentHeadContainer>
          <GoalContainer>
            <ArrowImg src={arrow} alt={"箭頭圖片"} />
            <MileStoneContainer>
              <MileStone>
                <CurrentStatusText>現階段</CurrentStatusText>
              </MileStone>
              <MileStoneTextContainer>
                <MileStoneText>走失寵物協尋</MileStoneText>
              </MileStoneTextContainer>
            </MileStoneContainer>
            <MileStoneContainer>
              <StrayPetsImg src={stray_dog} alt={"流浪狗圖片"} />
              <MileStone notAchieve />
              <MileStoneTextContainer>
                <MileStoneText>流浪動物安置、領養</MileStoneText>
              </MileStoneTextContainer>
            </MileStoneContainer>
          </GoalContainer>
        </GoalInfoContainer>
        <CurrentInpContainer>
          <CurrentHeadContainer>
            <CurrentHeadText isTransform>營運成本</CurrentHeadText>
          </CurrentHeadContainer>
          <CurrentTextContainer>
            <CurrentText>
              網域租用: 每年 <span>{state.domain} </span>元 （每日
              <span>{(state.domain / 365).toFixed(2)} </span>元）
            </CurrentText>
          </CurrentTextContainer>
          <CurrentTextContainer>
            <CurrentText>
              伺服器營運: 每年 <span>{state.server} </span>元 （每日
              <span>{(state.server / 365).toFixed(2)} </span>元）
            </CurrentText>
          </CurrentTextContainer>{" "}
          <CurrentTextContainer>
            <CurrentText>
              宣傳費: 每年 <span>{state.promotion} </span>元 （每日
              <span>{(state.promotion / 365).toFixed(2)} </span>元）
            </CurrentText>
          </CurrentTextContainer>{" "}
          <CurrentTextContainer>
            <CurrentText>
              維護成本: 每年 <span>{state.maintain} </span>元 （每日
              <span>{(state.maintain / 365).toFixed(2)} </span>元）
            </CurrentText>
          </CurrentTextContainer>{" "}
          <CurrentTextContainer>
            <CurrentText>
              開發人力成本: <span>{state.manpower} </span>元
            </CurrentText>
          </CurrentTextContainer>
        </CurrentInpContainer>
        <ThanksContainer>
          <CurrentHeadContainer>
            <CurrentHeadText>感謝</CurrentHeadText>
          </CurrentHeadContainer>
          <SwiperContainer spaceBetween={50} slidesPerView={2.5}>
            {state.thanksTo.map((i) => (
              <SwiperSlide>
                <ThanksCard name={i.name} amount={i.amount} time={i.time} />
              </SwiperSlide>
            ))}
          </SwiperContainer>
        </ThanksContainer>
        <ButtonContainer>
          <OpenDonationPage onClick={handleToggleDonationModal}>
            贊助我們
          </OpenDonationPage>
        </ButtonContainer>
      </CurrentStatusWrapper>
    </React.Fragment>
  );
};

const ThanksCard = ({ name, amount, time }) => {
  return (
    <ThanksCardWrapper>
      <ThanksTextContainer>
        <ThanksCardText>{name} 贊助</ThanksCardText>
      </ThanksTextContainer>
      <ThanksTextContainer>
        <ThanksCardText>
          <span>{amount}</span> 元
        </ThanksCardText>
      </ThanksTextContainer>
      <ThanksTextContainer end>
        <ThanksCardTimeText>{time}</ThanksCardTimeText>
      </ThanksTextContainer>
    </ThanksCardWrapper>
  );
};

const DonationModal = ({ isOpen, handleToggleDonationModal }) => {
  return (
    <React.Fragment>
      <Backdrop isOpen={isOpen} onClick={handleToggleDonationModal} />
      <DonationContainer isOpen={isOpen}>
        <DonationClose onClick={handleToggleDonationModal} />
        <DonationHeadContainer>
          <DonationHeadText>小額捐款給我們</DonationHeadText>
        </DonationHeadContainer>
        <DonationImgContainer>
          <DonationImg src={donation} alt={"捐款圖示"} />
        </DonationImgContainer>
        <DonationInfoContainer>
          <DonationInfoText>
            <span>玉山銀行（左營分行）</span>
          </DonationInfoText>
          <DonationInfoText>
            <span>(808)</span>0635-940-167416
          </DonationInfoText>
        </DonationInfoContainer>
      </DonationContainer>
    </React.Fragment>
  );
};

const Backdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: ${(p) => (p.isOpen ? "block" : "none")};
  transition: 0.2s left linear;
`;

const DonationContainer = styled.div`
  position: absolute;
  width: 90vw;
  height: 90vh;
  margin: 5vh 5vw;
  background-color: ${theme.white};
  border-radius: 20px;
  left: ${(p) => (p.isOpen ? "0px" : "-200vw")};
  transition: 0.2s left linear;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DonationClose = styled(Close)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const DonationHeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const DonationHeadText = styled.div`
  font-size: 25px;
  font-weight: 500;
`;

const DonationImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DonationImg = styled.img`
  width: 60%;
`;

const DonationInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DonationInfoText = styled.p`
  > span {
    font-weight: 500;
  }
`;

const CurrentStatusWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.bone};
  overflow: hidden;
`;

const CurrentHeadContainer = styled.div`
  width: 90%;
  margin: 0px 5%;
  padding: 10px 0px 0px 0px;
`;

const CurrentHeadText = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 10px 0px 0px 0px;
  padding: 10px 0px;
  transform: ${(p) => p.isTransform && "translateY(15px)"};
`;

const CurrentTextContainer = styled.div`
  width: 90%;
  margin: 0px 5%;
  transform: translateY(-10px);
`;

const CurrentText = styled.p`
  font-size: 15px;
  font-weight: 400;

  > span {
    font-size: 20px;
    font-weight: 500;
  }
`;

const GoalContainer = styled.div`
  width: 90%;
  margin: 0px 5%;
  padding: 10px 0px 20px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  transform: translateY(15px);
`;

const MileStoneContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MileStone = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: ${(p) => (p.notAchieve ? theme.coolGrey : theme.brickRed)};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(p) => (p.notAchieve ? "" : "translateY(-15px)")};
`;

const MileStoneTextContainer = styled.div``;

const MileStoneText = styled.p``;

const CurrentStatusText = styled.p`
  color: ${theme.white};
`;

const GoalInfoContainer = styled.div`
  width: 100vw;
  height: 25vh;
`;

const CurrentInpContainer = styled.div`
  width: 100vw;
  height: 30vh;
  position: relative;
  z-index: 10;
  padding-bottom: 15px;
`;

const ThanksContainer = styled.div`
  width: 100vw;
  /* height: 30vh; */
`;

const ButtonContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

const SwiperContainer = styled(Swiper)`
  width: 90%;
  margin: 0px 5%;
`;

const ThanksCardWrapper = styled.div`
  height: 10vh;
  width: 120px;
  background-color: ${theme.coolGrey};
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ThanksTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(p) => (p.end ? "flex-end" : "flex-start")};
`;

const ThanksCardText = styled.p`
  margin: 0px;
  color: ${theme.bone};
  > span {
    font-weight: 500;
  }
`;

const ThanksCardTimeText = styled.p`
  margin: 0px;
  width: 140px;
  color: ${theme.bone};
  font-size: 10px;
`;

const OpenDonationPage = styled(Button)`
  background-color: ${theme.davysGrey} !important;
  color: ${theme.coolGrey} !important;
  font-size: 20px !important;
  padding: 10px 40px !important;
`;

const DrinkingImgContainer = styled.div`
  position: absolute;
  overflow: hidden;
  width: 400px;
  height: 400px;
  opacity: 0.3;
  z-index: 1;
`;

const DrinkingImg = styled.img`
  position: absolute;
  overflow: hidden;
  width: 120px;
  opacity: 0.3;
  z-index: 0;
  right: 0px;
  bottom: 0px;
`;

const StrayPetsImg = styled.img`
  position: absolute;
  z-index: 0;
  width: 120px;
  height: 120px;
  z-index: 2;
  bottom: 70px;
`;

const ArrowImg = styled.img`
  position: absolute;
  z-index: 0;
  width: 300px;
  z-index: 0;
  transform: translateY(-30px) scaleX(1.2);
`;

export default CurrentStatus;
