import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { debounce } from "lodash";

import CustomMarker from "./CustomMarker";
import { selectMarkers } from "../slices/MarkersSlice";

const Maps = ({ handleSelectMarker, handleUpdateLocation }) => {
  const markers = useSelector(selectMarkers.selectAll);
  const { loadingStatus } = useSelector((state) => state.markers);
  const position = [23.6374115, 120.8873666];
  const [state, setState] = React.useState({
    map: null,
  });

  const onMove = React.useCallback(() => {
    const { lat, lng } = state.map.getCenter();
    onUpdateLocation(lat, lng);
  }, [state.map]);

  const onUpdateLocation = debounce((lat, lng) => {
    handleUpdateLocation(lat, lng);
  }, 500);

  React.useEffect(() => {
    if (state.map) {
      state.map.on("move", onMove);
      return () => {
        state.map.off("move", onMove);
      };
    }
  }, [state.map, onMove]);

  const displayMap = React.useMemo(
    () => (
      <MapContainers
        center={position}
        zoom={8}
        scrollWheelZoom={false}
        zoomControl={false}
        animate={true}
        tap={false}
        whenCreated={(loc) => {
          setState((state) => ({ ...state, map: loc }));
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {loadingStatus === "idle" && (
          <MarkerClusterGroup>
            {markers.map((i) => (
              <CustomMarker
                key={i.id}
                markerId={i._id}
                imgUrl={`https://api.waypethomes.com/images/${i.ImagePath[0]}`}
                position={i.loc.coordinates}
                isSelected={false}
                handleSelect={handleSelectMarker}
              />
            ))}
          </MarkerClusterGroup>
        )}
      </MapContainers>
    ),
    [markers, loadingStatus]
  );

  return displayMap;
};

const MapContainers = styled(MapContainer)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
`;

export default Maps;
