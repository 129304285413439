import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_URL,
  headers: { "Content-Type": "application/json" },
});

export const fetchMarkers = async (params) =>
  await post("api/MapSearch", {
    Line_UUID: params.lineUUID,
    Timelimit: params.timeLimit,
    longitude: params.longitude,
    latitude: params.latitude,
  });

export const createNewMessage = async (params) => {
  const ws = new WebSocket(`wss://api.waypethomes.com/ws/${params.markerId}`);

  ws.addEventListener("open", async () => {
    await ws.send({
      Line_UUID: params.lineUUID,
      Map_info_id: params.markerId,
      Tier1: params.messageIdTier1,
      Tier2: params.messageIdTier2,
      Private: params.isPrivate,
      MessageContent: params.messageContent,
    });
    ws.close();
  });
};
// await axios.post(`wss://api-stage.waypethomes.com/ws/${params.markerId}`, {
//   Line_UUID: params.lineUUID,
//   Map_info_id: params.markerId,
//   Tier1: params.messageIdTier1,
//   Tier2: params.messageIdTier2,
//   Private: params.isPrivate,
//   MessageContent: params.messageContent,
// });

export const fetchMessages = async (params) =>
  await client.post("api/GetMapInfoMarkerMessage", {
    Map_info_id: params.markerId,
  });

export const handleGetUserName = (params) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_CLIENT_URL}/api/Admin/GetBasicInfo`,
    data: JSON.stringify(params),
  }).then((res) => {
    return res.data;
  });
};

export const setUserInfo = async (params) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_CLIENT_URL}/api/Admin/CheckBasicInfo`,
    data: JSON.stringify({
      Line_UUID: params.Line_UUID,
      UpdateName: params.UpdateName,
    }),
  }).then((res) => {
    return res.data;
  });
};

export const handleValidUpdatePermission = (params) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_CLIENT_URL}/api/Admin/CheckUpdateFeatureAccess`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      Line_UUID: params.Line_UUID,
    }),
  })
    .then((res) => {
      return res.data;
    })
    .catch((res) => {
      return "無資料";
    });
};

export const handleUpdateMarker = async (params) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_CLIENT_URL}/api/AddMarkerFeature`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      Line_UUID: params.line_uuid,
      Map_info_id: params.map_info_id,
      AnimalType: params.animal_type,
      CoatColour: params.color,
      Variety: params.variety,
      Accessories: params.accessories,
      Remark: params.note,
    }),
  })
    .then((res) => {
      return res.data;
    })
    .catch((res) => {
      return "無資料";
    });
};

export const handleUpdateContactUs = async (params) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_CLIENT_URL}/api/Admin/ContactUs`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      Line_UUID: params.line_uuid,
      Content: params.content,
      Email: params.email,
    }),
  })
    .then((res) => {
      return res.data;
    })
    .catch((res) => {
      return "無資料";
    });
};

const post = async (url, body) => {
  console.log("post:", url, "body", body);
  const response = await client.post(url, JSON.stringify(body));
  console.log("response", response);
  return response;
};

const get = async (url, body) => {
  console.log("get:", url, "body", body);
  const response = await client.get(url, JSON.stringify(body));
  console.log("response", response);
  return response;
};
