import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Send from "@mui/icons-material/Send";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Close from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import liff from "@line/liff";

import Spinner from "../components/Spinner";
import * as theme from "../styles/index";
import logo from "../images/logo.png";

import {
  fetchMessages,
  selectMessages,
  createNewMessage,
  useGetMessagesQuery,
  selectMessageById,
} from "../slices/MessagesSlice";

const DetailInfo = ({
  isDetailInfoOpen,
  selectedId,
  handleCloseDetailInfo,
  handleToggleUpdateUserName,
  user_name,
}) => {
  const messageHistory = React.useRef([]);
  const chatRefItem = React.useRef();
  const chatRef = React.useRef();

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `wss://api.waypethomes.com/ws/${selectedId}`
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  messageHistory.current = React.useMemo(
    () => messageHistory.current.concat(lastMessage),
    [lastMessage]
  );

  const message = useSelector(selectMessages);
  const [state, setState] = React.useState({
    isComment: false,
    comment: "",
    message: [],
  });

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  const createNewMsg = React.useCallback(
    (len, tier1) => {
      if (!user_name) {
        handleToggleUpdateUserName();
      }
      liff
        .init({
          liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
        })
        .then(() => {
          const context = liff.getContext();
          const data = {
            Line_UUID: context.userId || "Ua2ef88c149ace10f40fe5cb654e089cf",
            Map_info_id: info.id,
            Tier1: tier1 || "",
            Tier2: "",
            Private: false,
            MessageContent: state.comment,
          };
          setTimeout(() => {
            scrollToBottom(len);
            setState((state) => ({ ...state, comment: "" }));
          }, 500);
          return sendMessage(JSON.stringify(data));
        });
    },
    [state.comment]
  );

  const scrollToBottom = (len) => {
    const h = chatRef.current.getBoundingClientRect().height;
    chatRef.current.scrollTo({
      top: h + 104 * (len * 3),
      behavior: "smooth",
    });
  };

  const filteredInfo = (id) => {
    let data;
    if (message.filter((i) => i._id.$oid === id).length > 0) {
      data = message.filter((i) => i._id.$oid === id)[0];
    } else {
      data = {
        Variety: "",
        CoatColour: "",
        message: [],
      };
    }
    return data;
  };

  const info = React.useMemo(() => filteredInfo(selectedId), [selectedId]);
  return (
    <DetailInfoWrapper isOpen={isDetailInfoOpen}>
      <DetailInfoWrapperImageContainer>
        <DetailInfoLogo>
          <DetailInfoLogoImg src={logo} alt={"logo 圖片"} />
        </DetailInfoLogo>
        <DetailInfoClose>
          <Close onClick={handleCloseDetailInfo} />
        </DetailInfoClose>
        <DetailInfoWrapperImage
          src={`https://api.waypethomes.com/images/${
            info.ImagePath ? info.ImagePath[0] : ""
          }`}
          alt={info.id}
        />
      </DetailInfoWrapperImageContainer>
      <DetailWrapperInfoItemContainer>
        {/* <DetailWrapperInfoItem>
          <DetailWrapperInfoItemName>
            <DetailWrapperInfoItemNameText>地點</DetailWrapperInfoItemNameText>
          </DetailWrapperInfoItemName>
          <DetailWrapperInfoItemValue>
            <DetailWrapperInfoItemValueText>
              {info.location}
            </DetailWrapperInfoItemValueText>
          </DetailWrapperInfoItemValue>
        </DetailWrapperInfoItem> */}
        <DetailWrapperInfoItem>
          <DetailWrapperInfoItemName>
            <DetailWrapperInfoItemNameText>毛色</DetailWrapperInfoItemNameText>
          </DetailWrapperInfoItemName>
          <DetailWrapperInfoItemValue>
            <DetailWrapperInfoItemValueText data={info.CoatColour}>
              {info.CoatColour || "無資料"}
            </DetailWrapperInfoItemValueText>
          </DetailWrapperInfoItemValue>
        </DetailWrapperInfoItem>
        <DetailWrapperInfoItem>
          <DetailWrapperInfoItemName>
            <DetailWrapperInfoItemNameText>品種</DetailWrapperInfoItemNameText>
          </DetailWrapperInfoItemName>
          <DetailWrapperInfoItemValue>
            <DetailWrapperInfoItemValueText data={info.Variety}>
              {info.Variety || "無資料"}
            </DetailWrapperInfoItemValueText>
          </DetailWrapperInfoItemValue>
        </DetailWrapperInfoItem>
        <DetailWrapperInfoItem>
          <DetailWrapperInfoItemName>
            <DetailWrapperInfoItemNameText>配件</DetailWrapperInfoItemNameText>
          </DetailWrapperInfoItemName>
          <DetailWrapperInfoItemValue>
            <DetailWrapperInfoItemValueText data={info.Accessories}>
              {info.Accessories || "無資料"}
            </DetailWrapperInfoItemValueText>
          </DetailWrapperInfoItemValue>
        </DetailWrapperInfoItem>
        <DetailWrapperInfoItem>
          <DetailWrapperInfoItemName>
            <DetailWrapperInfoItemNameText>備註</DetailWrapperInfoItemNameText>
          </DetailWrapperInfoItemName>
          <DetailWrapperInfoItemValue>
            <DetailWrapperInfoItemValueText data={info.Remark}>
              {info.Remark || "無資料"}
            </DetailWrapperInfoItemValueText>
          </DetailWrapperInfoItemValue>
        </DetailWrapperInfoItem>
      </DetailWrapperInfoItemContainer>
      <CommentItemContainerCommentContainer comment>
        <FirstLierCommentContainer>
          <CommentTextField
            name="comment"
            value={state.comment}
            onChange={handleUpdate}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Send
                    onClick={() =>
                      createNewMsg(
                        lastMessage
                          ? JSON.parse(lastMessage.data).data.Message.length
                          : 0
                      )
                    }
                  />
                </InputAdornment>
              ),
            }}
          />
        </FirstLierCommentContainer>
      </CommentItemContainerCommentContainer>
      {/* <SubscriptionContainer>
        <SubscriptionButton>有更新通知我</SubscriptionButton>
      </SubscriptionContainer> */}
      {connectionStatus !== "Open" ? (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
      ) : (
        <CommentWrapper ref={chatRef}>
          {lastMessage &&
            JSON.parse(lastMessage.data).data.Message.map((m) => {
              console.log(m);

              return (
                <CommentItem
                  lineUUID={m.Line_UUID || "Ua2ef88c149ace10f40fe5cb654e089cf"}
                  ref={chatRefItem}
                  userName={m.UserName}
                  key={m.Mid}
                  content={m.message_content}
                  ts={m.Timestamp}
                  isPrivate={m.Private}
                  message={m.Message}
                  id={m.Mid}
                  mapId={
                    info.message.length !== 0
                      ? info.message[0].Map_info_id.$oid
                      : ""
                  }
                  lastMessage={lastMessage}
                  sendMessage={sendMessage}
                />
              );
            })}
        </CommentWrapper>
      )}
    </DetailInfoWrapper>
  );
};

const CommentItem = ({
  content,
  ts,
  isPrivate,
  message,
  id,
  mapId,
  userName,
  lastMessage,
  sendMessage,
  lineUUID,
}) => {
  return (
    <CommentItemContainer>
      <MessageItem
        content={content}
        ts={ts}
        mapId={mapId}
        id={id}
        userName={userName}
        lastMessage={lastMessage}
        sendMessage={sendMessage}
        lineUUID={lineUUID}
      />
      {message.length > 0 && (
        <CommentItemSub>
          <CommentItemSubContainer>
            {message.length > 0 &&
              message.map((m) => (
                <MessageItem
                  content={m.message_content}
                  ts={m.Timestamp}
                  id={m.Mid}
                  firstLierId={id}
                  mapId={mapId}
                  userName={m.UserName}
                  lineUUID={m.lineUUID}
                />
              ))}
          </CommentItemSubContainer>
        </CommentItemSub>
      )}
    </CommentItemContainer>
  );
};

const MessageItem = ({
  content,
  ts,
  id,
  firstLierId,
  mapId,
  userName,
  lastMessage,
  sendMessage,
  lineUUID,
}) => {
  const { name } = useSelector((state) => state.user);
  const [state, setState] = React.useState({
    isComment: false,
    comment: "",
  });

  const toogleComment = () => {
    setState((state) => ({ ...state, isComment: !state.isComment }));
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  const createNewMsg = React.useCallback(() => {
    liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
      })
      .then(() => {
        const context = liff.getContext();
        const data = {
          Line_UUID: context.userId || "Ua2ef88c149ace10f40fe5cb654e089cf",
          Map_info_id: mapId,
          Tier1: id,
          Tier2: "",
          Private: false,
          MessageContent: state.comment,
        };
        setTimeout(() => {
          setState((state) => ({ ...state, comment: "" }));
        }, 500);
        return sendMessage(JSON.stringify(data));
      });
  }, [state.comment]);

  return (
    <React.Fragment>
      {/* <CommentItemContainerContent>
        <CommentItemContainerNameText
          userName={userName}
        ></CommentItemContainerNameText>
      </CommentItemContainerContent> */}
      <CommentItemContainerCommentTextContainer>
        <CommentItemContainerCommentTitleText>
          {userName && userName !== "none" ? userName : "匿名"}
          <span>{lineUUID && `(${lineUUID.substring(0, 4)})`}</span>
        </CommentItemContainerCommentTitleText>
        <CommentItemContainerCommentText>
          {content}
        </CommentItemContainerCommentText>
      </CommentItemContainerCommentTextContainer>
      {!firstLierId && (
        <CommentItemContainerCommentContainer comment>
          <CommentItemContainerDoCommentText onClick={toogleComment}>
            回覆留言
          </CommentItemContainerDoCommentText>
          <CommentItemContainerDateText>{ts}</CommentItemContainerDateText>
        </CommentItemContainerCommentContainer>
      )}
      <CommentItemContainerCommentContainer comment>
        <CommentCollapse in={state.isComment}>
          <CommentTextField
            name="comment"
            value={state.comment}
            onChange={handleUpdate}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Send onClick={createNewMsg} />
                </InputAdornment>
              ),
            }}
          />
        </CommentCollapse>
      </CommentItemContainerCommentContainer>
    </React.Fragment>
  );
};

const DetailInfoWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: ${(p) => (p.isOpen ? "0px" : "-120vw")};
  background-color: ${theme.white};
  z-index: 40;
  transition: 0.2s left linear;
  overflow-y: hidden;
`;

const DetailInfoWrapperImageContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 30vh;
  overflow: hidden;
`;

const DetailInfoWrapperImage = styled.img`
  /* background-image: ${(p) => `url(${p.path})`}; */
  object-fit: cover;
  width: 100vw;
  height: 30vh;
`;

const DetailInfoLogo = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: ${theme.white};
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailInfoClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  .MuiSvgIcon-root {
    color: ${theme.white};
  }
`;

const DetailInfoLogoImg = styled.img`
  width: 70px;
  height: 70px;
`;

const DetailWrapperInfoItemContainer = styled.div`
  width: 80%;
  margin: 10px 10%;
  padding: 15px 0px;
  border-bottom: 1px solid ${theme.grayColor};
`;

const DetailWrapperInfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 30px;
  padding: 2.5px 5px;
`;

const DetailWrapperInfoItemName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const DetailWrapperInfoItemNameText = styled.p`
  background-color: ${theme.grayColor};
  padding: 2.5px 5px;
  border-radius: 10px;
  min-width: 35px;
  font-size: 13px;
`;

const DetailWrapperInfoItemValue = styled.div`
  padding-left: 5px;
`;

const DetailWrapperInfoItemValueText = styled.p`
  font-size: 13px;
  font-weight: ${(p) => !p.data && 300};
  color: ${(p) => !p.data && theme.jet};
`;

const SubscriptionContainer = styled.div`
  width: 80%;
  margin: 10px 10%;
  display: flex;
  justify-content: flex-end;
`;

const SubscriptionButton = styled(Button)`
  background-color: ${theme.primaryColor} !important;
  color: ${theme.black} !important;
  border-radius: 20px !important;
  padding: 5px 15px !important;
`;

const CommentWrapper = styled.div`
  max-height: 280px;
  overflow: scroll;
`;

const LoadingWrapper = styled.div`
  min-height: 350px;
`;

const CommentItemContainer = styled.div`
  width: 80%;
  margin: 10px 10%;
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid ${theme.grayColor};
  position: relative;
`;

const CommentItemSubContainer = styled(CommentItemContainer)`
  width: 100%;
  margin: 10px 0%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: none;
`;

const CommentItemSub = styled.div`
  width: 80%;
  margin-left: 20%;
  display: flex;
  justify-content: flex-end;
`;

const CommentItemContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommentItemContainerNameText = styled.p`
  font-size: 13px;
  padding: 2px 10px;
  background-color: ${theme.primaryColor};
  color: ${(p) => (p.userName ? theme.black : theme.davysGrey)};
  border-radius: 10px;
`;

const CommentItemContainerDateText = styled.p`
  font-size: 13px;
  margin: 5px 0px;
`;

const CommentItemContainerCommentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.comment ? "flex-end" : "flex-start")};
`;

const CommentItemContainerCommentTextContainer = styled.div`
  background-color: ${theme.grayColor};
  padding: 10px 20px;
  border-radius: 7px;
  margin: 5px 0px;
`;

const CommentItemContainerCommentText = styled.p`
  font-size: 13px;
  margin: 5px 0px;

  > span {
    font-size: 14px;
    font-weight: 500;
  }
`;

const CommentItemContainerCommentTitleText = styled.p`
  font-size: 14px;
  margin: 5px 0px;
  font-weight: 500;

  > span {
    font-size: 10px !important;
    font-weight: 200;
    padding-left: 5px;
  }
`;

const CommentItemContainerDoCommentText = styled.p`
  font-size: 11px;
  margin: 5px 0px;
  color: #777777;
  cursor: pointer;
  transform: translateX(-20px);
`;

const CommentCollapse = styled(Collapse)`
  width: 100%;
`;

const CommentTextField = styled(TextField)`
  width: 100%;
  max-height: 40px;
  border-radius: 20px;

  .MuiOutlinedInput-root {
    max-height: 40px;
    border-radius: 20px;
    background-color: ${theme.white};
    border: 1.5px solid ${theme.grayColor};
  }

  .MuiInputAdornment-root {
    .MuiSvgIcon-root {
      font-size: 20px;
      transform: translateX(10px);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const SubscriptionDot = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${theme.errorColor};
  left: 70px;
  top: 22px;
`;

const FirstLierCommentContainer = styled.div`
  width: 80%;
  margin: 10px 10%;
`;

export default DetailInfo;
