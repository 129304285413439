import React from "react";
import styled from "styled-components";
import liff from "@line/liff";
import MenuItem from "@mui/material/MenuItem";
import TextFiled from "@mui/material/TextField";
import Calender from "@mui/icons-material/CalendarToday";
import { useDispatch, useSelector } from "react-redux";

import { setUserInfo, handleGetUserName } from "../service/client";

import * as theme from "../styles/index";

const NameInputs = ({ isOpen, handleClose, handleToggleUpdateUserName }) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    is_name_too_long: false,
    userName: "",
  });

  React.useEffect(() => {
    liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
      })
      .then(() => {
        const context = liff.getContext();
        handleGetUserName({
          Line_UUID: context.userId || "Ua8fff8f775143db31edccdba82304853",
        }).then((res) => {
          setState((state) => ({ ...state, userName: res }));
        });
      });
  }, []);

  React.useEffect(() => {
    if (state.userName.length >= 10) {
      setState((state) => ({ ...state, is_name_too_long: true }));
    } else {
      setState((state) => ({ ...state, is_name_too_long: false }));
    }
  }, [state.userName]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setState((state) => ({ ...state, [name]: value }));
  };

  const handleUpdateUserName = () => {
    if (state.userName) {
      liff
        .init({
          liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
        })
        .then(() => {
          const context = liff.getContext();
          setUserInfo({
            Line_UUID: context.userId || "Ua8fff8f775143db31edccdba82304853",
            UpdateName: state.userName || "none",
          });
          handleClose();
        });
    }
  };

  const handleUseAnonymous = () => {
    liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
      })
      .then(() => {
        const context = liff.getContext();
        setUserInfo({
          Line_UUID: context.userId || "Ua8fff8f775143db31edccdba82304853",
          UpdateName: "none",
        });
        handleClose();
      });
    handleClose();
  };

  return (
    <React.Fragment>
      <NameInputContainer>
        <NameInputContainerModal isModalOpen={isOpen}>
          <NameInputContainerModalHeader>
            <NameInputContainerModalHeaderText>
              使用者名稱
            </NameInputContainerModalHeaderText>
          </NameInputContainerModalHeader>
          <NameInputContainerModalSelectionContainer>
            <NameInputContainerModalSelectionItem>
              <NameInputContainerModalSelectionItemSelect
                value={state.userName}
                onChange={handleChange}
                name={"userName"}
              />
            </NameInputContainerModalSelectionItem>
            {state.is_name_too_long && (
              <NameInputTooLongText>字數不得超過10</NameInputTooLongText>
            )}
            {state.userName === "none" && (
              <NameInputTooLongText>
                請勿輸入無效使用者名稱
              </NameInputTooLongText>
            )}
          </NameInputContainerModalSelectionContainer>
          <NameInputContainerModalButtonContainer>
            <NameInputContainerModalSubButton
              onClick={
                state.is_name_too_long || state.userName === "none"
                  ? () => {}
                  : handleUpdateUserName
              }
            >
              儲存
            </NameInputContainerModalSubButton>
            <NameInputContainerModalCanSubButton onClick={handleUseAnonymous}>
              使用匿名
            </NameInputContainerModalCanSubButton>
          </NameInputContainerModalButtonContainer>
          <NameInputContainerModalSelectionContainer>
            <NameInputContainerExplainItem>
              <NameInputContainerModalSelectionItemKeyText>
                您可以輸入使用者名稱，或是不輸入以匿名方式留言。
              </NameInputContainerModalSelectionItemKeyText>
            </NameInputContainerExplainItem>
          </NameInputContainerModalSelectionContainer>
        </NameInputContainerModal>
        <NameInputContainerBackdrop
          isModalOpen={isOpen}
          onClick={handleClose}
        />
      </NameInputContainer>
    </React.Fragment>
  );
};

const NameInputTooLongText = styled.p`
  color: ${theme.errorColor};
  text-align: center;
  font-size: 10px;
`;

const NameInputMenu = styled.div`
  display: flex;
  transform: translateY(-5px);
`;

const NameInputMenuContainer = styled.div`
  background-color: ${theme.white};
  border-radius: 50px 0px 0px 50px;
`;

const NameInputMenuSelection = styled(TextFiled)`
  /* background-color: red; */
  min-width: 90px !important;
  padding: 5px 5px 5px 15px !important;

  > div {
    #mui-component-select-timeRange {
      transform: translateY(3px);
    }
  }

  > div::after {
    border-bottom: 1px solid ${theme.white} !important;
    border-style: none !important;
  }
  > div::before {
    border-bottom: 1px solid ${theme.white} !important;
    border-style: none !important;
  }

  .MuiSvgIcon-root {
    background-color: ${theme.primaryColor};
    border-radius: 50%;
    transform: translateX(2.5px);
  }
`;

const NameInputMenuOption = styled(MenuItem)``;

const NameInputDropdownContainer = styled.div`
  background-color: ${theme.primaryColor};
  border-radius: 0px 50px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 5px;
`;

const CalenderIcon = styled(Calender)`
  width: 20px !important;
  height: 20px !important;
  color: ${theme.primaryColor};
  background-color: ${theme.white} !important;
`;

const NameInputContainer = styled.div``;

const NameInputContainerBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: ${(p) => (p.isModalOpen ? "block" : "none")};
  background-color: ${theme.black};
  left: 0px;
  top: 0px;
  z-index: 45;
  opacity: 0.4;
  transition: 0.2s display ease-in;
`;

const NameInputContainerModal = styled.div`
  position: absolute;
  width: 80vw;
  margin: 100px 10vw;
  min-height: 30vh;
  background-color: ${theme.white};
  left: ${(p) => (p.isModalOpen ? "0px" : "-120vw")};
  top: 0px;
  z-index: 50;
  border-radius: 20px;
  transition: 0.2s left linear;
  display: flex;
  flex-direction: column;
`;

const NameInputContainerModalHeader = styled.div`
  background-color: ${theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
`;

const NameInputContainerModalHeaderText = styled.p`
  padding-left: 5px;
`;

const NameInputContainerModalSelectionContainer = styled.div`
  margin: 5px 0px 0px 0px;
`;

const NameInputContainerModalSelectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 5px 10%;
`;

const NameInputContainerExplainItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 10px 10%;
`;

const NameInputContainerModalSelectionItemKey = styled.div`
  padding-right: 10%;
`;

const NameInputContainerModalSelectionItemKeyText = styled.p`
  width: 90%;
  font-size: 12px;
  text-align: left;
`;

const NameInputContainerModalSelectionItemSelect = styled(TextFiled)`
  width: 90%;
  > div {
    height: 40px;
    border-radius: 20px;
    > fieldset {
      border-color: ${theme.primaryColor} !important;
    }
  }
`;

const NameInputContainerModalButtonContainer = styled.div``;

const NameInputContainerModalSubButton = styled.div`
  width: 80%;
  margin: 10px 10%;
  background-color: ${theme.primaryColor};
  border-radius: 20px;
  padding: 10px 0px;
  text-align: center;
`;

const NameInputContainerModalCanSubButton = styled(
  NameInputContainerModalSubButton
)`
  background-color: ${theme.grayColor};
`;

export default NameInputs;
