import React from "react";
import styled from "styled-components";
import Menu from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";

import MenuCard from "./MenuCard";
import BottomMenuSwitchTab from "./BottomMenuSwitchTab";
import * as theme from "../styles";

import { selectMarkers } from "../slices/MarkersSlice";

const BottomMenu = ({ handleOpenDetailInfo }) => {
  const markers = useSelector(selectMarkers.selectAll);
  const { name } = useSelector((state) => state.user);

  const [state, setState] = React.useState({
    isMenuOpen: false,
  });

  const handleToggleMenu = () => {
    setState((state) => ({ ...state, isMenuOpen: !state.isMenuOpen }));
  };

  const handleOpenDetailInfos = (id) => {
    handleToggleMenu();
    handleOpenDetailInfo(id);
  };

  return (
    <BottomMenuSwitchTabWrapper isMenuOpen={state.isMenuOpen}>
      <BottomMenuTab onClick={handleToggleMenu} isMenuOpen={state.isMenuOpen}>
        <BottomMenuTabIconContainer isMenuOpen={state.isMenuOpen}>
          <BottomMenuTabIcon />
        </BottomMenuTabIconContainer>
        <BottomMenuTabText isMenuOpen={state.isMenuOpen}>
          回報列表
        </BottomMenuTabText>
      </BottomMenuTab>
      <BottomMenuSwitchTab />
      <ItemWrapper>
        {markers
          .sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp))
          .map((item) => (
            <MenuCard
              key={item._id.$oid}
              imgUrl={item.ImagePath[0]}
              id={item._id.$oid}
              location={item.location}
              skinColor={item.CoatColour}
              breed={item.Variety}
              comments={item.message}
              accessories={item.Accessories}
              time={item.Timestamp}
              handleOpenDetailInfo={() => {
                handleOpenDetailInfos(item._id.$oid);
              }}
            />
          ))}
      </ItemWrapper>
    </BottomMenuSwitchTabWrapper>
  );
};

const BottomMenuSwitchTabWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: ${theme.grayColor};
  top: ${(p) => (p.isMenuOpen ? "0px" : "calc(100vh - 65px)")};
  transition: 0.3s top linear;
  z-index: 20;
`;

const BottomMenuTab = styled.div`
  height: ${(p) => (p.isMenuOpen ? "50px" : "65px")};
  background-color: ${theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s height linear;
`;

const BottomMenuTabText = styled.p`
  margin: 0px;
  padding: 0px 0px 3px 10px;
  transform: ${(p) => (p.isMenuOpen ? "translateY(0px)" : "translateY(-10px)")};
`;

const BottomMenuTabIconContainer = styled.div`
  transform: ${(p) => (p.isMenuOpen ? "translateY(0px)" : "translateY(-10px)")};
`;

const BottomMenuTabIcon = styled(Menu)`
  color: ${theme.white};
`;

const ItemWrapper = styled.div`
  max-height: calc(100vh - 50px - 60px);
  overflow: scroll;
`;

export default BottomMenu;
