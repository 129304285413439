export const data = [
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
  { id: Math.random(), name: "大賣", amount: 250, time: "2021-12-05" },
];
