import React from "react";
import styled from "styled-components";
import TextFiled from "@mui/material/TextField";
import liff from "@line/liff";

import Spinner from "../components/Spinner";

import { handleUpdateContactUs } from "../service/client";
import * as theme from "../styles/index";

const ContactUsPages = () => {
  const [state, setState] = React.useState({
    lading: false,
    email: "",
    content: "",
    is_send: false,
    err_msg: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((state) => ({ ...state, [name]: value }));
  };

  const handleUpdate = () => {
    setState((state) => ({ ...state, loading: true }));

    liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
      })
      .then(function () {
        const context = liff.getContext(); // 取得使用者類型資料

        const params = {
          line_uuid: context.userId || "Ua2ef88c149ace10f40fe5cb654e089cf",
          content: state.content,
          email: state.email,
        };
        handleUpdateContactUs(params).then((res) => {
          if (res.status === "success") {
            setState((state) => ({
              ...state,
              loading: false,
              is_send: true,
            }));
          } else {
            setState((state) => ({
              ...state,
              loading: false,
              is_send: true,
              err_msg: res.detail,
            }));
          }
        });
      })
      .catch(function (error) {
        setState((state) => ({
          ...state,
          loading: false,
          is_send: true,
          err_msg: error,
        }));
      });
  };

  if (state.loading) {
    return (
      <OptionsNoDataWrapper>
        <Spinner />
      </OptionsNoDataWrapper>
    );
  }

  if (state.is_send && !state.err_msg) {
    return (
      <OptionsNoDataWrapper>
        <OptionsNoDataTextContainer>
          <OptionsNoDataText>已收到您的訊息。</OptionsNoDataText>
          <OptionsNoDataText>謝謝您，我們將盡快回覆。🐶 🐱</OptionsNoDataText>
        </OptionsNoDataTextContainer>
        <FilterContainerModalButtonContainer>
          <FilterContainerModalSubButton
            onClick={() => {
              window.close();
            }}
          >
            關閉視窗
          </FilterContainerModalSubButton>
        </FilterContainerModalButtonContainer>
      </OptionsNoDataWrapper>
    );
  }

  if (state.is_send && state.err_msg) {
    return (
      <OptionsNoDataWrapper>
        <OptionsNoDataTextContainer>
          <OptionsNoDataText>系統發生問題。{state.err_msg}</OptionsNoDataText>
          <OptionsNoDataText>麻煩透過 Instagram</OptionsNoDataText>
          <OptionsNoDataText> @wayhome_wayhome 聯絡我們。</OptionsNoDataText>
        </OptionsNoDataTextContainer>

        <FilterContainerModalButtonContainer>
          <FilterContainerModalSubButton
            onClick={() => {
              window.close();
            }}
          >
            關閉視窗
          </FilterContainerModalSubButton>
        </FilterContainerModalButtonContainer>
      </OptionsNoDataWrapper>
    );
  }

  return (
    <OptionsWrapper>
      <FilterContainerModalSelectionItem>
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            E-mail
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelect
          onChange={handleChange}
          name={"email"}
          value={state.email}
        />
      </FilterContainerModalSelectionItem>
      <FilterContainerModalSelectionItem>
        {" "}
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            內容
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelectTextArea
          onChange={handleChange}
          name={"content"}
          value={state.content}
          multiline
          maxRows={4}
          id="outlined-multiline-flexible"
        />
      </FilterContainerModalSelectionItem>
      <FilterContainerModalButtonContainer>
        <FilterContainerModalSubButton
          disabled={!state.email || !state.content}
          onClick={!state.email || !state.content ? () => {} : handleUpdate}
        >
          聯絡我們
        </FilterContainerModalSubButton>
      </FilterContainerModalButtonContainer>
    </OptionsWrapper>
  );
};

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
`;

const OptionsNoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
`;

const OptionsNoDataText = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${theme.coolGrey};
  margin: 0px;
`;

const OptionsNoDataTextContainer = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilterContainerModalSelectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin: 10px 10%;
`;

const FilterContainerModalSelectionItemKey = styled.div`
  padding-right: 10%;
`;

const FilterContainerModalSelectionItemKeyText = styled.p`
  background-color: ${theme.grayColor};
  padding: 2.5px 5px;
  border-radius: 10px;
  min-width: 35px;
  font-size: 12px;
  text-align: center;
`;

const FilterContainerModalSelectionItemSelect = styled(TextFiled)`
  width: 90%;
  > div {
    height: 40px;
    border-radius: 20px;
    > fieldset {
      border-color: ${theme.primaryColor} !important;
    }
  }
`;

const FilterContainerModalSelectionItemSelectTextArea = styled(
  FilterContainerModalSelectionItemSelect
)`
  > div {
    height: 150px;
    > textarea {
      height: 130px !important;
    }
    > fieldset {
      border-color: ${theme.primaryColor} !important;
    }
  }
`;

const FilterContainerModalButtonContainer = styled.div`
  width: 100%;
`;

const FilterContainerModalSubButton = styled.div`
  width: 80%;
  margin: 10px 10%;
  background-color: ${(p) =>
    p.disabled ? theme.grayColor : theme.primaryColor};
  border-radius: 20px;
  padding: 10px 0px;
  text-align: center;
  color: ${(p) => (p.disabled ? theme.white : theme.black)};
`;

export default ContactUsPages;
