import styled from "styled-components";
import Marker from "react-leaflet-enhanced-marker";

import * as theme from "../styles/index";

const CustomMarker = ({
  isSelected,
  position,
  imgUrl,
  handleSelect,
  markerId,
}) => {
  return (
    <Marker
      key={markerId}
      icon={<CustomMarkerComp handleSelect={handleSelect} imgUrl={imgUrl} />}
      position={[position[1], position[0]]}
      eventHandlers={{
        click: (e) => {
          handleSelect(markerId);
        },
      }}
    ></Marker>
  );
};

const CustomMarkerComp = ({ imgUrl }) => {
  return (
    <CustomMarkerContainer>
      <CustomMarkerImageContainer>
        <CustomMarkerImage src={imgUrl} alt={"1"} />
      </CustomMarkerImageContainer>
      <CustomMarkerLine />
      <CustomMarkerDot />
    </CustomMarkerContainer>
  );
};

const CustomMarkerContainer = styled.div`
  position: relative;
`;

const CustomMarkerImageContainer = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${theme.white};
  border-radius: 50%;
  border: 2px solid ${theme.primaryColor};
`;

const CustomMarkerImage = styled.img`
  width: 60px;
  height: 60px;
  background-color: ${theme.white};
  border-radius: 50%;
  object-fit: cover;
`;

const CustomMarkerLine = styled.div`
  border: 1px solid ${theme.primaryColor};
  width: 10px;
  transform: rotate(90deg) translate(5px, -27px);
`;

const CustomMarkerDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${theme.primaryColor};
  border-radius: 50%;
  transform: translate(29px, 5px);
`;

export default CustomMarker;
