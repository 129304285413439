import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import CurrentStatus from "./screens/CurrentStatus";
import OptionsPages from "./screens/OptionsPage";
import ContactUsPages from "./screens/ContactUs";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "./store";
import LineProvider from "./components/LineProvider";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/contact_us">
            <ContactUsPages />
          </Route>
          <Route path="/options">
            <OptionsPages />
          </Route>
          <Route path="/current_status">
            <CurrentStatus />
          </Route>
          <Route path="/">
            <App />
            {/* <LineProvider></LineProvider> */}
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
