import React from "react";
import styled from "styled-components";
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";

import * as theme from "../styles";

const MenuList = ({ handleToggleUpdateUserName }) => {
  const [state, setState] = React.useState({
    open: false,
  });

  const handleToggleMenu = () =>
    setState((state) => ({ ...state, open: !state.open }));

  return (
    <React.Fragment>
      <BackDrop onClick={handleToggleMenu} open={state.open}>
        <MenuContainer onClick={handleToggleUpdateUserName}>
          <EditIcon />
          <MenuContainerText>修改使用者名稱</MenuContainerText>
        </MenuContainer>
      </BackDrop>
      <ListMenuIconContainer>
        <ListMenuIcon onClick={handleToggleMenu} />
      </ListMenuIconContainer>
    </React.Fragment>
  );
};

const BackDrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0px;
  top: ${(p) => (p.open ? "0px" : "120vh")};
  z-index: 500;
  transition: 0.2s top linear;
`;

const MenuContainer = styled.div`
  position: absolute;
  bottom: 115px;
  left: 35px;
  background-color: ${theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  z-index: 510;
`;

const MenuContainerText = styled.p`
  font-size: 12px;
  color: ${theme.jet};
  width: 80%;
  text-align: center;
`;

const ListMenuIconContainer = styled.div`
  position: absolute;
  left: 10px;
  bottom: 80px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  background-color: ${theme.primaryColor};
`;

const ListMenuIcon = styled(List)`
  color: ${theme.white};
`;

const EditIcon = styled(Edit)`
  color: ${theme.jet};
  width: 20%;
  text-align: center;
  transform: translateX(10px);
`;

export default MenuList;
