import React from "react";
import styled from "styled-components";
import liff from "@line/liff";

import Map from "./components/Maps";
import BottomMenu from "./components/BottomMenu";
import DisplayMarkerInfo from "./components/DisplayMarkerInfo";
import Search from "./components/Search";
import Filter from "./components/Filter";
import NameInput from "./components/NameInputModal";
import DetailInfo from "./screens/DetailInfo";
import PhotoPreview from "./components/PhotoPreview";
import MenuList from "./components/MenuList";
import Loading from "./components/Loading";

import * as theme from "./styles/index";

import { useDispatch, useSelector } from "react-redux";
import { fetchMarkers, startLoading } from "./slices/MarkersSlice";
import { fetchUserName } from "./slices/UserSlice";
import { handleGetUserName } from "./service/client";

import logo from "./images/logo.png";

function App() {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    isDisplayMarkerInfoOpen: false,
    lineUUID: "",
    selectedMarkerId: "",
    selectedId: "",
    isDetailInfoOpen: false,
    longitude: "",
    latitude: "",
    timeFrame: 720,
    isInputNameModalOpen: false,
    isPhotoPreviewOpen: false,
    user_name: "",
  });

  React.useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setState((state) => ({
          ...state,
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        }));
      });
    } else {
      setState((state) => ({
        ...state,
        longitude: 120.335444506696,
        latitude: 22.6530250971847,
      }));
      console.log("Geolocation Not Available");
    }
  }, []);

  React.useEffect(() => {
    if (state.longitude && state.latitude) {
      liff
        .init({
          liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
        })
        .then(() => {
          const context = liff.getContext();
          dispatch(
            fetchMarkers({
              lineUUID: context.userId || "Ua8fff8f775143db31edccdba82304853",
              timeLimit: state.timeFrame,
              longitude: state.longitude,
              latitude: state.latitude,
            })
          );
        });
    }
  }, [state.latitude, state.longitude, state.timeFrame]);

  React.useEffect(() => {
    dispatch(
      fetchMarkers({
        lineUUID: "Ua8fff8f775143db31edccdba82304853",
        timeLimit: 720,
        longitude: state.longitude,
        latitude: state.latitude,
      })
    );
  }, [state.longitude, state.latitude]);

  React.useEffect(() => {
    liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
      })
      .then(() => {
        const context = liff.getContext();
        handleGetUserName({
          Line_UUID: context.userId || "Ua8fff8f775143db31edccdba82304853",
        }).then((res) => {
          setState((state) => ({ ...state, user_name: res }));
        });
      });
  }, []);

  const handleForceCloseNameModal = () => {
    setState((state) => ({ ...state, isInputNameModalOpen: false }));
  };

  const handleSelectMarker = (id) => {
    setState((state) => ({
      ...state,
      isDisplayMarkerInfoOpen: !state.isDisplayMarkerInfoOpen,
    }));
    if (id) {
      //從底下打開詳細頁面不能把 id 刪掉，所以拿這個來判斷從哪裡開啟的
      if (id.$oid === state.selectedMarkerId) {
        setState((state) => ({ ...state, selectedMarkerId: "" }));
      } else {
        setState((state) => ({ ...state, selectedMarkerId: id.$oid }));
      }
    }
  };

  const handleOpenDetailInfo = (id) => {
    setState((state) => ({
      ...state,
      isDetailInfoOpen: true,
      selectedId: id,
      selectedMarkerId: "",
    }));
  };

  const handleCloseDetailInfo = () => {
    setState((state) => ({
      ...state,
      isDetailInfoOpen: false,
      selectedId: "",
    }));
  };

  const handleSetTimeFrame = (num) => {
    setState((state) => ({ ...state, timeFrame: num }));
  };

  const handleTogglePhotoPreview = () => {
    setState((state) => ({
      ...state,
      isPhotoPreviewOpen: !state.isPhotoPreviewOpen,
    }));
  };

  const handleToggleUpdateUserName = () => {
    setState((state) => ({
      ...state,
      isInputNameModalOpen: !state.isInputNameModalOpen,
    }));
  };

  const handleUpdateLocation = (lat, long) => {
    setState((state) => ({
      ...state,
      longitude: long,
      latitude: lat,
    }));
  };

  return (
    <AppWrapper>
      <Loading />
      <MenuList handleToggleUpdateUserName={handleToggleUpdateUserName} />
      <PhotoPreview
        open={state.isPhotoPreviewOpen}
        selectedMarkerId={state.selectedMarkerId}
        handleTogglePhotoPreview={handleTogglePhotoPreview}
      />
      <NameInput
        isOpen={state.isInputNameModalOpen}
        handleClose={handleForceCloseNameModal}
        handleToggleUpdateUserName={handleToggleUpdateUserName}
      />
      <DetailInfo
        isDetailInfoOpen={state.isDetailInfoOpen}
        handleCloseDetailInfo={handleCloseDetailInfo}
        selectedId={state.selectedId}
        handleToggleUpdateUserName={handleToggleUpdateUserName}
        user_name={state.user_name}
      />
      <HeaderContainer>
        <LogoContainer>
          <Logo>
            <LogoImg src={logo} alt={"logo 圖片"} />
          </Logo>
        </LogoContainer>
        <HeaderTextContainer>
          <HeaderText>返家途中</HeaderText>
          <HeaderSubText>WAY HOME</HeaderSubText>
        </HeaderTextContainer>
        <Filter handleSetTimeFrame={handleSetTimeFrame} />
      </HeaderContainer>
      <Map
        longitude={state.longitude}
        latitude={state.latitude}
        handleUpdateLocation={handleUpdateLocation}
        handleSelectMarker={handleSelectMarker}
      />
      {/* <SearchContainer>
        <Search />
      </SearchContainer> */}
      <BottomMenu handleOpenDetailInfo={handleOpenDetailInfo} />
      <DownerSectionWrapper
        isDisplayMarkerInfoOpen={state.isDisplayMarkerInfoOpen}
      >
        <DisplayMarkerInfo
          isDisplayMarkerInfoOpen={state.isDisplayMarkerInfoOpen}
          handleSelectMarker={handleSelectMarker}
          selectedMarkerId={state.selectedMarkerId}
          handleOpenDetailInfo={handleOpenDetailInfo}
          handleTogglePhotoPreview={handleTogglePhotoPreview}
        />
      </DownerSectionWrapper>
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  position: absolute;
  z-index: 15;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.014443277310924318) 0%,
    rgba(248, 248, 247, 0.3897934173669467) 16%,
    rgba(163, 151, 138, 0.6643032212885154) 47%,
    rgba(130, 115, 93, 0.9) 67%,
    rgba(105, 79, 46, 0.9) 100%
  );
`;

const DownerSectionWrapper = styled.div`
  position: absolute;
  bottom: ${(p) => (p.isDisplayMarkerInfoOpen ? "-10px" : "-260px")};
  z-index: 20;
  transition: 0.2s bottom linear;
`;

const LogoContainer = styled.div`
  width: 20%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const LogoImg = styled.img`
  width: 50px;
  height: 50px;
`;

const HeaderTextContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  transform: translateY(-5px);
`;

const HeaderText = styled.div`
  text-align: justify;
  font-size: 1.15rem;
  margin: 0px;
  color: ${theme.primaryColor};
  width: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 3px;
  width: 100%;
  transform: translateX(-5px);
`;

const HeaderSubTextContainer = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const HeaderSubText = styled.div`
  text-align: justify;
  font-size: 10px;
  margin: 0px;
  color: ${theme.white};
  width: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-left: 1px solid ${theme.white};
  border-right: 1px solid ${theme.white};
`;

const HeaderFilterContainer = styled.div``;

const HeaderFilter = styled.div``;

const SearchContainer = styled.div`
  position: absolute;
  bottom: 60px;
`;

export default App;
