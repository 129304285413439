export const white = "#fff";
export const black = "#000";
export const primaryColor = "#ffd833";
export const primaryVariantColor = "#FFDE55";
export const secondary = "#7A6A56E6";
export const secondaryVariantColor = "#7D6E5ADF";
export const secondaryLightColor = "#BBB3A875";
export const errorColor = "#DB543A";
export const grayColor = "#efefef";
export const jet = "#2d2d2aff";
export const davysGrey = "#4c4c47ff";
export const coolGrey = "#848fa5ff";
export const brickRed = "#c14953ff";
export const bone = "#e5dcc5ff";
