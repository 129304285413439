import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import * as client from "../service/client";
import LoadingStatus from "../common/LoadingStatus";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const messagessAdaptor = createEntityAdapter();

const initialState = messagessAdaptor.getInitialState({
  loadingStatus: LoadingStatus.Idle,
  errorMessage: "",
  createLoadingStatus: LoadingStatus.Idle,
  createErrorMessage: "",
});

//thunk
/**
 * @param markerId string
 */
export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({ markerId }, thunkApi) => {
    try {
      const response = await client.fetchMessages({ markerId });
      return { ...response.data, id: response.data._id.$oid };
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

/**
 * @param lineUUID string
 * @param markerId string
 * @param messageIdTier1 string
 * @param messageIdTier2 string
 * @param messageContent string
 * @param isPrivate boolean
 */
export const createNewMessage = createAsyncThunk(
  "messages/createNewMessage",
  async (params, thunkApi) => {
    try {
      const response = await client.createNewMessage(params);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

//slice
const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchMessages.pending, (state) => {
        setLoadingStatus(state, LoadingStatus.Loading);
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        messagessAdaptor.upsertOne(state, action.payload);
        console.log(action.payload);
        setLoadingStatus(state, LoadingStatus.Idle);
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        setLoadingStatus(
          state,
          LoadingStatus.Idle,
          action.payload ? action.payload.detail[0].msg : action.error
        );
      })
      .addCase(createNewMessage.pending, (state) => {
        setMessageCreateLoadingStatus(state, LoadingStatus.Loading);
      })
      .addCase(createNewMessage.rejected, (state, action) => {
        setMessageCreateLoadingStatus(
          state,
          LoadingStatus.Idle,
          action.payload ? action.payload.detail[0].msg : action.error
        );
      })
      .addCase(createNewMessage.fulfilled, (state) => {
        setMessageCreateLoadingStatus(state, LoadingStatus.Idle);
      }),
});

const setLoadingStatus = (state, status, errorMessage) => {
  state.loadingStatus = status;
  state.errorMessage = errorMessage ?? "";
};

const setMessageCreateLoadingStatus = (state, status, errorMessage) => {
  state.createLoadingStatus = status;
  state.createErrorMessage = errorMessage ?? "";
};

export default messagesSlice.reducer;

//websocket
export const messagesApi = createApi({
  reducerPath: "wsMessages",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.waypethomes.com",
  }),
  endpoints: (build) => ({
    getMessages: build.query({
      query: (markerId) => ({
        url: `api/GetMapInfoMarkerMessage`,
        method: "POST",
        body: { Map_info_id: markerId },
      }),
      transformResponse(response) {
        return messagessAdaptor.addMany(
          messagessAdaptor.getInitialState(),
          response
        );
      },
      async onCacheEntryAdded(
        markerId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        //TODO: extract repetitive url
        let ws = new WebSocket(`wss://api.waypethomes.com/ws/${markerId}`);

        try {
          await cacheDataLoaded;

          const listener = (event) => {
            const data = JSON.parse(event.data);
            //TODO: need to check if the data is message
            console.log("ws data", data);
            updateCachedData((draft) => {
              messagessAdaptor.upsertOne(draft, data.data);
            });
          };
          ws.addEventListener("message", listener);
        } catch (e) {
          console.log(e);
          //TODO: link action.payload / action.error
        }
        await cacheEntryRemoved;
        ws.close();
      },
    }),
  }),
});

export const { useGetMessagesQuery } = messagesApi;

//selectors
export const { selectAll: selectMessages, selectById: selectMessageById } =
  messagessAdaptor.getSelectors((state) => state.markers);

export const selectMessageIds = createSelector(selectMessages, (markers) =>
  markers.map((marker) => marker.id)
);
