import React from "react";
import styled from "styled-components";
import TextFiled from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Calender from "@mui/icons-material/CalendarToday";
import Filter from "@mui/icons-material/FilterAlt";
import liff from "@line/liff";
import axios from "axios";

import Spinner from "../components/Spinner";

import {
  handleValidUpdatePermission,
  handleUpdateMarker,
} from "../service/client";
import { dogs, cats } from "../constants";
import * as theme from "../styles/index";

const OptionsPages = () => {
  const [state, setState] = React.useState({
    lading: false,
    animal_type: "",
    color: "",
    variety: "",
    accessories: "",
    isAuthorized: true,
    displayText: "",
    note: "",
    updated: false,
  });

  const handlePostLineData = (data) => {
    return axios({
      method: "post",
      url: `https://hello-yu-chen-default-rtdb.firebaseio.com/test.json`,
      data: data,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  React.useEffect(() => {
    setState((state) => ({ ...state, loading: true }));
    setTimeout(() => {
      liff
        .init({
          liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
        })
        .then(function () {
          const context = liff.getContext(); // 取得使用者類型資料
          handlePostLineData(context);
          handleValidUpdatePermission({
            Line_UUID: context.userId || "Ua8fff8f775143db31edccdba82304853",
          }).then((res) => {
            setState((state) => ({
              ...state,
              displayText: res,
              loading: false,
            }));

            if (res !== "ok") {
              setState((state) => ({
                ...state,
                isAuthorized: false,
                loading: false,
              }));
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }, 1500);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((state) => ({ ...state, [name]: value }));
  };

  const handleUpdate = () => {
    setState((state) => ({ ...state, loading: true }));

    liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID, // Use own liffId
      })
      .then(function () {
        const context = liff.getContext(); // 取得使用者類型資料

        const params = {
          line_uuid: context.userId || "Ua2ef88c149ace10f40fe5cb654e089cf",
          map_info_id: "",
          animal_type: state.animal_type,
          color: state.color,
          variety: state.variety,
          accessories: state.accessories,
          note: state.note,
        };
        handleUpdateMarker(params).then((res) => {
          setState((state) => ({ ...state, updated: true, loading: false }));
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  if (state.loading) {
    return (
      <OptionsNoDataWrapper>
        <Spinner />
      </OptionsNoDataWrapper>
    );
  }

  if (!state.isAuthorized) {
    return (
      <OptionsNoDataWrapper>
        <OptionsNoDataText>您無此頁權限。{state.displayText}</OptionsNoDataText>
      </OptionsNoDataWrapper>
    );
  }

  if (state.updated) {
    return (
      <OptionsNoDataWrapper>
        <OptionsNoDataText>上傳成功。感謝您的協助！🐶 🐱</OptionsNoDataText>
        <FilterContainerModalButtonContainer>
          <FilterContainerModalSubButton
            onClick={() => {
              window.close();
            }}
          >
            關閉視窗
          </FilterContainerModalSubButton>
        </FilterContainerModalButtonContainer>
      </OptionsNoDataWrapper>
    );
  }

  return (
    <OptionsWrapper>
      <FilterContainerHeader>
        <FilterContainerHeaderText>上傳浪浪特徵</FilterContainerHeaderText>
        <FilterContainerText>
          請選擇浪浪特徵後點選<span>上傳資料</span>
        </FilterContainerText>
      </FilterContainerHeader>
      <FilterContainerModalSelectionItem>
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            種類
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelect
          select
          onChange={handleChange}
          name={"animal_type"}
          value={state.animal_type}
        >
          <MenuItem value="狗">狗</MenuItem>
          <MenuItem value="貓">貓</MenuItem>
          <MenuItem value="其他">其他</MenuItem>
        </FilterContainerModalSelectionItemSelect>
      </FilterContainerModalSelectionItem>
      <FilterContainerModalSelectionItem>
        {" "}
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            毛色
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelect
          select
          onChange={handleChange}
          name={"color"}
          value={state.color}
        >
          <MenuItem value="黑色">黑色</MenuItem>
          <MenuItem value="白色">白色</MenuItem>
          <MenuItem value="虎斑色">虎斑色</MenuItem>
          <MenuItem value="赤色">赤色</MenuItem>
          <MenuItem value="胡麻色">胡麻色</MenuItem>
          <MenuItem value="其他">其他</MenuItem>
        </FilterContainerModalSelectionItemSelect>
      </FilterContainerModalSelectionItem>
      <FilterContainerModalSelectionItem>
        {" "}
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            品種
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelect
          select
          onChange={handleChange}
          name={"variety"}
          value={state.variety}
        >
          {state.animal_type === "狗"
            ? dogs.map((i) => (
                <MenuItem key={i.name} value={i.name}>
                  {i.name}
                </MenuItem>
              ))
            : cats.map((i) => (
                <MenuItem key={i.name} value={i.name}>
                  {i.name}
                </MenuItem>
              ))}
        </FilterContainerModalSelectionItemSelect>
      </FilterContainerModalSelectionItem>
      <FilterContainerModalSelectionItem>
        {" "}
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            配件
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelect
          onChange={handleChange}
          name={"accessories"}
          value={state.accessories}
        />
      </FilterContainerModalSelectionItem>
      <FilterContainerModalSelectionItem>
        {" "}
        <FilterContainerModalSelectionItemKey>
          <FilterContainerModalSelectionItemKeyText>
            備註
          </FilterContainerModalSelectionItemKeyText>
        </FilterContainerModalSelectionItemKey>
        <FilterContainerModalSelectionItemSelect
          onChange={handleChange}
          name={"note"}
          value={state.note}
        />
      </FilterContainerModalSelectionItem>
      <FilterContainerModalButtonContainer>
        <FilterContainerModalSubButton onClick={handleUpdate}>
          上傳資料
        </FilterContainerModalSubButton>
      </FilterContainerModalButtonContainer>
    </OptionsWrapper>
  );
};

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
`;

const OptionsNoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const OptionsNoDataText = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${theme.coolGrey};
`;

const Mask = styled.div`
  position: absolute;
  width: 80vw;
  margin: 100px 10vw;
  min-height: 60vh;
  background-color: ${theme.coolGrey};
  opacity: 0.7;
  left: ${(p) => (p.isModalOpen ? "0px" : "-120vw")};
  top: 0px;
  z-index: 16;
  border-radius: 20px;
  transition: 0.2s left linear;
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    font-size: 2rem;
    color: ${theme.white};
  }
`;

const FilterMenu = styled.div`
  display: flex;
  transform: translateY(-5px);
`;

const FilterMenuContainer = styled.div`
  background-color: ${theme.white};
  border-radius: 50px 0px 0px 50px;
`;

const FilterMenuSelection = styled(TextFiled)`
  /* background-color: red; */
  min-width: 90px !important;
  padding: 5px 5px 5px 15px !important;

  > div {
    #mui-component-select-timeRange {
      transform: translateY(3px);
    }
  }

  > div::after {
    border-bottom: 1px solid ${theme.white} !important;
    border-style: none !important;
  }
  > div::before {
    border-bottom: 1px solid ${theme.white} !important;
    border-style: none !important;
  }

  .MuiSvgIcon-root {
    background-color: ${theme.primaryColor};
    border-radius: 50%;
    transform: translateX(2.5px);
  }
`;

const FilterMenuOption = styled(MenuItem)``;

const FilterDropdownContainer = styled.div`
  background-color: ${theme.primaryColor};
  border-radius: 0px 50px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px 5px;
`;

const CalenderIcon = styled(Calender)`
  width: 20px !important;
  height: 20px !important;
  color: ${theme.primaryColor};
  background-color: ${theme.white} !important;
`;

const FilterIcon = styled(Filter)`
  width: 20px !important;
  height: 20px !important;
  color: ${theme.white};
  cursor: pointer;
`;

const FilterContainer = styled.div``;

const FilterContainerBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: ${(p) => (p.isModalOpen ? "block" : "none")};
  background-color: ${theme.black};
  left: 0px;
  top: 0px;
  z-index: -1;
  opacity: 0.4;
  transition: 0.2s display ease-in;
`;

const FilterContainerModal = styled.div`
  position: absolute;
  width: 80vw;
  margin: 100px 10vw;
  min-height: 60vh;
  background-color: ${theme.white};
  left: ${(p) => (p.isModalOpen ? "0px" : "-120vw")};
  top: 0px;
  z-index: 15;
  border-radius: 20px;
  transition: 0.2s left linear;
`;

const FilterContainerModalHeader = styled.div`
  background-color: ${theme.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
`;

const FilterContainerModalHeaderText = styled.p`
  padding-left: 5px;
`;

const FilterContainerModalSelectionContainer = styled.div``;

const FilterContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0px 10%;
`;

const FilterContainerHeaderText = styled.h2`
  margin: 5px 0px;
`;

const FilterContainerText = styled.p`
  margin: 5px 0px;
  > span {
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const FilterContainerModalSelectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 10px 10%;
`;

const FilterContainerModalSelectionItemKey = styled.div`
  padding-right: 10%;
`;

const FilterContainerModalSelectionItemKeyText = styled.p`
  background-color: ${theme.grayColor};
  padding: 2.5px 5px;
  border-radius: 10px;
  min-width: 35px;
  font-size: 12px;
  text-align: center;
`;

const FilterContainerModalSelectionItemSelect = styled(TextFiled)`
  width: 90%;
  > div {
    height: 40px;
    border-radius: 20px;
    > fieldset {
      border-color: ${theme.primaryColor} !important;
    }
  }
`;

const FilterContainerModalButtonContainer = styled.div`
  width: 100%;
`;

const FilterContainerModalSubButton = styled.div`
  width: 80%;
  margin: 10px 10%;
  background-color: ${theme.primaryColor};
  border-radius: 20px;
  padding: 10px 0px;
  text-align: center;
`;

const FilterContainerModalCanSubButton = styled(FilterContainerModalSubButton)`
  background-color: ${theme.grayColor};
`;

export default OptionsPages;
