import React from "react";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import * as theme from "../styles";

const BottomMBottomMenuSwitchTab = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomMenuSwitchTabWrapper>
      <BottomMenuSwitchTabs value={value} onChange={handleChange}>
        <BottomMenuSwitchTab label="所有走失列表" />
        {/* <BottomMenuSwitchTab label="已加通知列表" /> */}
      </BottomMenuSwitchTabs>
      <div index={0} />
      <div index={1} />
    </BottomMenuSwitchTabWrapper>
  );
};

const BottomMenuSwitchTabWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
`;

const BottomMenuSwitchTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${theme.primaryColor};
    transform: translateY(-5px);
  }
`;

const BottomMenuSwitchTab = styled(Tab)`
  color: ${theme.black} !important;
  padding: 2px 16px !important;
`;

export default BottomMBottomMenuSwitchTab;
