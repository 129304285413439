import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import * as client from "../service/client";
import LoadingStatus from "../common/LoadingStatus";

const markersAdaptor = createEntityAdapter({
  loadingStatus: LoadingStatus.Idle,
  errorMessage: "",
  selectId: (marker) => marker.id,
});

const initialState = markersAdaptor.getInitialState();

//thunk
/**
 * @param lineUUID string
 * @param timeLimit number
 * @param longitude number
 * @param latitude number
 */
export const fetchMarkers = createAsyncThunk(
  "markers/fetchMarkers",
  async (params, thunkApi) => {
    try {
      const response = await client.fetchMarkers(params);
      return response.data.map((marker) => ({
        ...marker,
        id: marker._id.$oid,
      }));
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

//slice
const markersSlice = createSlice({
  name: "markers",
  initialState,
  reducers: {
    setAllMarkers: markersAdaptor.setAll,
  },
  extraReducers: {
    [fetchMarkers.pending](state) {
      setLoadingStatus(state, LoadingStatus.Loading);
    },
    [fetchMarkers.fulfilled](state, { payload }) {
      setLoadingStatus(state, LoadingStatus.Idle);
      markersAdaptor.setAll(state, payload);
    },
    [fetchMarkers.rejected](state, action) {
      setLoadingStatus(
        state,
        LoadingStatus.Idle,
        action.payload.detail[0].msg ?? action.error
      );
    },
  },
});

const setLoadingStatus = (state, status, errorMessage) => {
  state.loadingStatus = status;
  state.errorMessage = errorMessage ?? "";
};

export default markersSlice.reducer;

export const selectMarkers = markersAdaptor.getSelectors(
  (state) => state.markers
);
