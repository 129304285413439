import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as client from "../service/client";
import LoadingStatus from "../common/LoadingStatus";

const initialState = {
  lineUUID: "initial line id",
  name: "",
  updateLoadingStatus: LoadingStatus.Idle,
  getUserNameLoading: LoadingStatus.Idle,
  errorMessage: "",
};

/**
 * @param lineUUID string
 * @param updateName string
 */
export const setUserInfo = createAsyncThunk(
  "user/setupUserInfo",
  async (userInfo, thunkApi) => {
    try {
      await client.setUserInfo(userInfo);
      return userInfo.updateName;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserName = createAsyncThunk(
  "user/fetchUserName",
  async (params, thunkApi) => {
    try {
      const response = await client.handleGetUserName(params);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setupUserState: (state, action) => {
      console.log(action);
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserName.pending, (state) => {
      state.getUserNameLoading = LoadingStatus.Idle;
      state.errorMessage = "";
    });
    builder.addCase(fetchUserName.rejected, (state, action) => {
      state.getUserNameLoading = LoadingStatus.Idle;
      // state.errorMessage = action.payload.detail[0].msg ?? action.error;
    });
    builder.addCase(fetchUserName.fulfilled, (state, action) => {
      //TODO: need an api to read user

      console.log(action);

      state.getUserNameLoading = LoadingStatus.Idle;
      state.name = action.payload;

      // state.errorMessage = "";
    });
  },
});

export const { setupUserState } = userSlice.actions;

export default userSlice.reducer;
