import React from "react";
import styled from "styled-components";
import ThumbUp from "@mui/icons-material/ThumbUp";

import * as theme from "../styles";

import dogs from "../images/dog.png";

const MenuCard = ({
  imgUrl,
  id,
  location,
  skinColor,
  breed,
  comments,
  handleOpenDetailInfo,
  accessories,
  time,
}) => {
  return (
    <MenuCardWrapper>
      <MenuCardWrapperLeft>
        <MenuCardWrapperImage
          src={`https://api.waypethomes.com/images/${imgUrl}`}
          alt={`${id}圖片`}
        />
      </MenuCardWrapperLeft>
      <MenuCardWrapperRight>
        <MenuCardWrapperRightInfo>
          <MenuCardWrapperRightInfoItem>
            <MenuCardWrapperRightInfoItemName>
              <MenuCardWrapperRightInfoItemNameText>
                毛色
              </MenuCardWrapperRightInfoItemNameText>
            </MenuCardWrapperRightInfoItemName>
            <MenuCardWrapperRightInfoItemValue>
              <MenuCardWrapperRightInfoItemValueText data={skinColor}>
                {skinColor || "無資料"}
              </MenuCardWrapperRightInfoItemValueText>
            </MenuCardWrapperRightInfoItemValue>
          </MenuCardWrapperRightInfoItem>
          <MenuCardWrapperRightInfoItem>
            <MenuCardWrapperRightInfoItemName>
              <MenuCardWrapperRightInfoItemNameText>
                品種
              </MenuCardWrapperRightInfoItemNameText>
            </MenuCardWrapperRightInfoItemName>
            <MenuCardWrapperRightInfoItemValue>
              <MenuCardWrapperRightInfoItemValueText data={breed}>
                {breed || "無資料"}
              </MenuCardWrapperRightInfoItemValueText>
            </MenuCardWrapperRightInfoItemValue>
          </MenuCardWrapperRightInfoItem>
          <MenuCardWrapperRightInfoItem>
            <MenuCardWrapperRightInfoItemName>
              <MenuCardWrapperRightInfoItemNameText>
                配件
              </MenuCardWrapperRightInfoItemNameText>
            </MenuCardWrapperRightInfoItemName>
            <MenuCardWrapperRightInfoItemValue>
              <MenuCardWrapperRightInfoItemValueText data={accessories}>
                {accessories || "無資料"}
              </MenuCardWrapperRightInfoItemValueText>
            </MenuCardWrapperRightInfoItemValue>
          </MenuCardWrapperRightInfoItem>
        </MenuCardWrapperRightInfo>
        {/* <MenuCardWrapperRightComments>
          {comments?.map((comment) => (
            <MenuCardWrapperRightCommentItem
              key={comment.id}
              content={comment.content}
              author={comment.author}
              ts={comment.ts}
            />
          ))}
        </MenuCardWrapperRightComments> */}
      </MenuCardWrapperRight>
      <MenuCardWrapperRightMoreInfo onClick={handleOpenDetailInfo}>
        MORE ...
      </MenuCardWrapperRightMoreInfo>
      <DateText>{time}</DateText>
    </MenuCardWrapper>
  );
};

const MenuCardWrapperRightCommentItem = ({ content, author, ts }) => {
  let date;

  return (
    <MenuCardWrapperRightCommentItemContainer>
      <MenuCardWrapperRightCommentItemContainerContentContainer>
        <MenuCardWrapperRightCommentItemContainerContent>
          {content}
        </MenuCardWrapperRightCommentItemContainerContent>
        <MenuCardWrapperRightCommentItemContainerInfo>
          - {author},
        </MenuCardWrapperRightCommentItemContainerInfo>
      </MenuCardWrapperRightCommentItemContainerContentContainer>
      <MenuCardWrapperRightCommentItemThumbUpContainer>
        <MenuCardWrapperRightCommentItemThumbUpIcon />
      </MenuCardWrapperRightCommentItemThumbUpContainer>
    </MenuCardWrapperRightCommentItemContainer>
  );
};

const MenuCardWrapper = styled.div`
  background-color: ${theme.white};
  width: 90%;
  margin: 5%;
  display: flex;
  min-height: 150px;
  border-radius: 20px;
  position: relative;
`;

const MenuCardWrapperLeft = styled.div`
  width: 30%;
`;

const MenuCardWrapperImage = styled.img`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  padding: 10px;
  object-fit: cover;
`;

const MenuCardWrapperRight = styled.div`
  width: calc(70% -20px);
`;

const MenuCardWrapperRightInfo = styled.div`
  padding: 10px 0px 0px 22px;
`;

const MenuCardWrapperRightInfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 30px;
`;

const MenuCardWrapperRightInfoItemName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const MenuCardWrapperRightInfoItemNameText = styled.p`
  background-color: ${theme.grayColor};
  padding: 2.5px 5px;
  border-radius: 10px;
  min-width: 35px;
  font-size: 12px;
`;

const MenuCardWrapperRightInfoItemValue = styled.div`
  padding-left: 5px;
  margin-right: 5px;
`;

const MenuCardWrapperRightInfoItemValueText = styled.p`
  font-size: 12px;
  font-weight: ${(p) => !p.data && 300};
  color: ${(p) => !p.data && theme.jet};
`;

const MenuCardWrapperRightComments = styled.div`
  padding: 0px 0px 10px 22px;
  max-height: 80px;
  overflow: scroll;
  margin-bottom: 5px;
`;

const MenuCardWrapperRightCommentItemContainer = styled.div`
  display: flex;
  max-height: 45px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  border-top: 0.5px solid ${theme.grayColor};
`;
const MenuCardWrapperRightCommentItemContainerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  padding: 2px 0px;
`;
const MenuCardWrapperRightCommentItemContainerContent = styled.p`
  margin: 0px;
  font-size: 10px;
`;
const MenuCardWrapperRightCommentItemContainerInfo = styled.p`
  margin: 0px;
  font-size: 5px;
  font-weight: 700;
`;
const MenuCardWrapperRightCommentItemThumbUpContainer = styled.div`
  padding: 2px;
`;
const MenuCardWrapperRightCommentItemThumbUpIcon = styled(ThumbUp)`
  color: ${theme.primaryColor};
`;

const MenuCardWrapperRightMoreInfoContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  padding: 2px 0px 5px 0px;
`;

const MenuCardWrapperRightMoreInfo = styled.p`
  font-size: 10px;
  letter-spacing: -1px;
  font-weight: 700;
  padding: 5px 10px;
  background-color: ${theme.primaryColor};
  color: ${theme.white};
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: 0px;
`;

const DateContainer = styled.div``;

const DateText = styled.p`
  position: absolute;
  bottom: -5px;
  left: 20px;
  font-size: 10px;
`;

export default React.memo(MenuCard);
