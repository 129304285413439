import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import Close from "@mui/icons-material/Close";

import { selectMarkers } from "../slices/MarkersSlice";

import * as theme from "../styles";
import "swiper/swiper-bundle.css";

const PhotoPreview = ({ open, selectedMarkerId, handleTogglePhotoPreview }) => {
  const markers = useSelector(selectMarkers.selectAll);

  const filteredInfo = (id) => {
    let data;
    if (
      markers.length > 0 &&
      +markers.filter((i) => i._id.$oid === id).length > 0
    ) {
      data = markers.filter((i) => i._id.$oid === id)[0]["ImagePath"];
    } else {
      data = [];
    }
    return data;
  };

  const photoUrl = React.useMemo(
    () => filteredInfo(selectedMarkerId),
    [selectedMarkerId]
  );

  console.log(markers);

  return (
    <React.Fragment>
      <CloseIcon open={open} onClick={handleTogglePhotoPreview} />
      <PhotoPreviewWrapper open={open} onClick={handleTogglePhotoPreview} />
      <SwiperContainer spaceBetween={50} slidesPerView={1} open={open}>
        {photoUrl.map((p) => (
          <SwiperSlide>
            <PhotoContainer key={p}>
              <Photo src={`https://api.waypethomes.com/images/${p}`} />
            </PhotoContainer>
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </React.Fragment>
  );
};

const CloseIcon = styled(Close)`
  position: absolute;
  right: 5px;
  top: 15px;
  color: ${theme.white};
  z-index: 1007;
  display: ${(p) => (p.open ? "block" : "none")} !important;
  transition: 0.1s display ease-in-out;
`;

const PhotoPreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1005;
  display: ${(p) => (p.open ? "block" : "none")};
  transition: 0.1s display ease-in-out;
`;

const SwiperContainer = styled(Swiper)`
  position: absolute;
  left: ${(p) => (p.open ? "0px" : "-150vw")};
  transition: 0.3s left ease-in-out;
  z-index: 1006;
`;

const PhotoContainer = styled.div`
  width: 90vw;
  height: 80vh;
  margin: 10vh 5vw;
  border-radius: 40px;
  background-color: ${theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${theme.primaryColor};
`;

const Photo = styled.img`
  width: 95%;
  height: 97%;
  border-radius: 40px;
  object-fit: cover;
`;

export default PhotoPreview;
