import { setupListeners } from "@reduxjs/toolkit/query";

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/UserSlice";
import markersReducer from "./slices/MarkersSlice";
import messagesReducer from "./slices/MessagesSlice";
import { messagesApi } from "./slices/MessagesSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    markers: markersReducer,
    messages: messagesReducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(messagesApi.middleware),
});

setupListeners(store.dispatch);

export default store;
