import React from "react";
import styled, { keyframes } from "styled-components";
import Search from "@mui/icons-material/Search";

import * as theme from "../styles";

const Searchs = () => {
  const [state, setState] = React.useState({
    isSearchBarOpen: false,
  });

  const handleToogleSearchBar = () => {
    setState((state) => ({
      ...state,
      isSearchBarOpen: !state.isSearchBarOpen,
    }));
  };

  return (
    <SearchWrapper>
      <SearchIconContainer onClick={handleToogleSearchBar}>
        <SearchIcons />
      </SearchIconContainer>
      <SearchInputContainer isSearchBarOpen={state.isSearchBarOpen}>
        <SearchInput />
      </SearchInputContainer>
    </SearchWrapper>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SearchWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  position: relative;
`;

const SearchIconContainer = styled.div`
  background-color: ${theme.primaryColor};
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-20px);
  z-index: 10;
  position: absolute;
`;

const SearchIcons = styled(Search)`
  animation: ${rotate} 2s linear;
  color: ${theme.white};
`;

const SearchInputContainer = styled.div`
  position: absolute;
  width: ${(p) => (p.isSearchBarOpen ? "90%" : "0%")};
  background-color: ${theme.white};
  right: 20px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 25px;
  z-index: 0px;
  transition: 0.2s width ease-in;
`;

const SearchInput = styled.input`
  width: 85%;
  height: 35px;
  border-style: none;
  transform: translateX(-15px);
`;

export default Searchs;
