import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as theme from "../styles/index";
import { selectMarkers, selectMarkerById } from "../slices/MarkersSlice";

const DisplayMarkerInfo = ({
  isDisplayMarkerInfoOpen,
  handleSelectMarker,
  selectedMarkerId,
  handleOpenDetailInfo,
  handleTogglePhotoPreview,
}) => {
  const markers = useSelector(selectMarkers.selectAll);
  // const marker = useSelector(selectMarkerById(selectedMarkerId))
  const images = [
    {
      backgroundColor: "rgba(135, 121, 103, 1)",
    },
    {
      backgroundColor: "rgba(142, 129, 111, 1)",
    },
    {
      backgroundColor: "rgba(163, 151, 138, 1)",
    },
    {
      backgroundColor: "rgba(192, 184, 175, 1)",
    },
    {
      backgroundColor: "rgba(224, 220, 215, 1)",
    },
  ];

  const filteredInfo = (id) => {
    let data;
    if (markers.filter((i) => i._id.$oid === id).length > 0) {
      data = markers.filter((i) => i._id.$oid === id)[0];
    } else {
      data = {
        Variety: "",
        CoatColour: "",
      };
    }
    return data;
  };

  const info = React.useMemo(
    () => filteredInfo(selectedMarkerId),
    [selectedMarkerId]
  );

  const handleOpenDetail = () => {
    handleOpenDetailInfo(info._id.$oid);
    handleSelectMarker();
  };

  return (
    <DisplayMarkerInfoWrapper>
      <BackDrop
        onClick={handleSelectMarker}
        isDisplayMarkerInfoOpen={isDisplayMarkerInfoOpen}
      />
      <DisplayImagesList>
        {images.map((i, index) => {
          let img = "";
          //TODO: extract url
          if (info.ImagePath && info.ImagePath[index]) {
            img = `https://api.waypethomes.com/images/${info.ImagePath[index]}`;
          }

          return (
            <DetailImageCard
              key={i + index}
              handleTogglePhotoPreview={handleTogglePhotoPreview}
              backgroundColor={i.backgroundColor}
              imgUrl={img}
              // alt="detail_info_images_1"
            />
          );
        })}
      </DisplayImagesList>
      {/* <DividerSpanContainer>
        <DividerSpan />
      </DividerSpanContainer> */}
      <DisplayMarkerInfoWrapperInfo>
        {/* <DisplayMarkerInfoWrapperInfoItem>
          <DisplayMarkerInfoWrapperInfoItemName>
            <DisplayMarkerInfoWrapperInfoItemNameText>
              地點
            </DisplayMarkerInfoWrapperInfoItemNameText>
          </DisplayMarkerInfoWrapperInfoItemName>
          <DisplayMarkerInfoWrapperInfoItemValue>
            <DisplayMarkerInfoWrapperInfoItemValueText>
              (google地圖回傳地址)
            </DisplayMarkerInfoWrapperInfoItemValueText>
          </DisplayMarkerInfoWrapperInfoItemValue>
        </DisplayMarkerInfoWrapperInfoItem> */}
        <DisplayMarkerInfoWrapperInfoItem>
          <DisplayMarkerInfoWrapperInfoItemName>
            <DisplayMarkerInfoWrapperInfoItemNameText>
              毛色
            </DisplayMarkerInfoWrapperInfoItemNameText>
          </DisplayMarkerInfoWrapperInfoItemName>
          <DisplayMarkerInfoWrapperInfoItemValue>
            <DisplayMarkerInfoWrapperInfoItemValueText data={info.CoatColour}>
              {info.CoatColour || "無資料"}
            </DisplayMarkerInfoWrapperInfoItemValueText>
          </DisplayMarkerInfoWrapperInfoItemValue>
        </DisplayMarkerInfoWrapperInfoItem>
        <DisplayMarkerInfoWrapperInfoItem>
          <DisplayMarkerInfoWrapperInfoItemName>
            <DisplayMarkerInfoWrapperInfoItemNameText>
              品種
            </DisplayMarkerInfoWrapperInfoItemNameText>
          </DisplayMarkerInfoWrapperInfoItemName>
          <DisplayMarkerInfoWrapperInfoItemValue>
            <DisplayMarkerInfoWrapperInfoItemValueText data={info.Variety}>
              {info.Variety || "無資料"}
            </DisplayMarkerInfoWrapperInfoItemValueText>
          </DisplayMarkerInfoWrapperInfoItemValue>
        </DisplayMarkerInfoWrapperInfoItem>
        <DisplayMarkerInfoWrapperInfoItem>
          <DisplayMarkerInfoWrapperInfoItemName>
            <DisplayMarkerInfoWrapperInfoItemNameText>
              配件
            </DisplayMarkerInfoWrapperInfoItemNameText>
          </DisplayMarkerInfoWrapperInfoItemName>
          <DisplayMarkerInfoWrapperInfoItemValue>
            <DisplayMarkerInfoWrapperInfoItemValueText data={info.Accessories}>
              {info.Accessories || "無資料"}
            </DisplayMarkerInfoWrapperInfoItemValueText>
          </DisplayMarkerInfoWrapperInfoItemValue>
        </DisplayMarkerInfoWrapperInfoItem>
      </DisplayMarkerInfoWrapperInfo>
      <DisplayMarkerInMoreInfo onClick={handleOpenDetail}>
        MORE ...
      </DisplayMarkerInMoreInfo>
    </DisplayMarkerInfoWrapper>
  );
};

const DetailImageCard = ({
  imgUrl,
  alt,
  backgroundColor,
  handleTogglePhotoPreview,
}) => {
  return (
    <DisplayImageContainer
      backgroundColor={backgroundColor}
      onClick={handleTogglePhotoPreview}
    >
      {imgUrl && <DisplayImage src={imgUrl} alt={alt} />}
    </DisplayImageContainer>
  );
};

const offSetHeight = (window.innerHeight - 200) * -1;

const BackDrop = styled.div`
  display: ${(p) => (p.isDisplayMarkerInfoOpen ? "block" : "none")};
  background-color: ${theme.black};
  width: 100vw;
  height: calc(100vh - 200px);
  position: absolute;
  transform: translateY(${offSetHeight + "px"});
  opacity: 0;
`;

const DisplayMarkerInfoWrapper = styled.div`
  width: 100vw;
  height: 200px;
  background-color: ${theme.white};
`;

const DisplayImagesList = styled.div`
  width: 100%;
  display: flex;
`;

const DisplayImageContainer = styled.div`
  width: calc(100vw / 5);
  height: calc(100vw / 5);
  background-color: ${(p) => p.backgroundColor};
`;

const DisplayImage = styled.img`
  width: calc(100vw / 5);
  height: calc(100vw / 5);
  object-fit: cover;
`;

const DisplayMarkerInfoWrapperInfo = styled.div`
  width: 80%;
  margin: 5px 10% 10px 10%;
`;

const DisplayMarkerInfoWrapperInfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 30px;
`;

const DisplayMarkerInfoWrapperInfoItemName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const DisplayMarkerInfoWrapperInfoItemNameText = styled.p`
  background-color: ${theme.grayColor};
  padding: 2.5px 5px;
  border-radius: 10px;
  min-width: 35px;
  font-size: 12px;
`;

const DisplayMarkerInfoWrapperInfoItemValue = styled.div`
  padding-left: 5px;
  margin-right: 5px;
`;

const DisplayMarkerInfoWrapperInfoItemValueText = styled.p`
  font-size: 12px;
  font-weight: ${(p) => !p.data && 300};
  color: ${(p) => !p.data && theme.jet};
`;

const DividerSpanContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

const DividerSpan = styled.span`
  width: 30px;
  height: 3px;
  border-radius: 40px;
  background-color: ${theme.primaryColor};
`;

const DisplayMarkerInMoreInfo = styled.p`
  font-size: 10px;
  letter-spacing: -1px;
  font-weight: 700;
  padding: 5px 10px;
  background-color: ${theme.primaryColor};
  color: ${theme.white};
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export default DisplayMarkerInfo;
